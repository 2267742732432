/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, formatHM, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqPtqj",
  components: {
    LCFQheader
  },
  data() {
    return {
      headTit: "新建申请",
      tit: "普通请假",
      oneft: "/index",
      checkbox: false,
      showData: false,
      showDataStrar: false,
      showDataEnd: false,
      minDate: new Date(),
      currentDate: new Date(),
      minDateRL: new Date(),
      dqDate: new Date(),
      single: false,
      allowSelect: true,
      showUserDialog: false,
      solId: "",
      boDefId: "",
      formKey: "",
      data: {
        dlrName: '',
        ljcs: "",
        ks: "",
        jqlxName: '',
        qjfsName: ''
      },
      userId: ""
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  mounted() {},
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
    },
    async cgORxj() {
      let instId = this.$route.params.instId;
      // console.log(typeof(busad))
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";

        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          this_.data.dlrName = this.setSelectData(resData.dlr, resData.dlr_name);
          this_.data.dbsx = resData.dbsx;
          this_.data.jqlxName = resData.jqlx_name;
          this_.data.qjfsName = resData.qjfs_name;
          this_.data.ks = resData.ks;
          this_.data.qjrq = resData.qjrq;
          this_.data.qssj = resData.qssj;
          this_.data.hjsj = resData.sqjqhjsj;
          this_.data.xm = resData.xm;
          this_.data.xs = resData.xs;
          this_.data.zzsj = resData.zzsj;
          this_.data.ljcs = resData.byljcs;
        });
        /***/
      } else {
        this.getUser();
      }
    },
    setSelectData(strId, strName) {
      let zzzObj = [{
        id: strId,
        text: strName
      }];
      return JSON.stringify(zzzObj);
    },
    getUser() {
      this.data.xm = RxUtil.getCache("userName");
      // this.data.ks = RxUtil.getCache("mainGroupName");
      var userID = RxUtil.getCache("userID");
      var userid = {
        F_XM: userID
      };
      var params = new FormData();
      params.append("params", JSON.stringify(userid));
      var url = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_byljsc.do";
      this.$ajax.post(url, params).then(res => {
        var ljcs = res.data.data.length;
        this.data.ljcs = ljcs;
      });
      var xmId = {
        XMID: userID
      };
      var paramsId = new FormData();
      paramsId.append("params", JSON.stringify(xmId));
      var urlGetUser = _baseUrl + "/customizeProc/annualLeaveForm/annualLeaveForm/getUser.do?ids=";
      this.$ajax.post(urlGetUser, paramsId).then(resUser => {
        this.userId = resUser.data.userId;
        let UserId = {
          PARTY2_: resUser.data.identityId
        };
        let UserIdString = JSON.stringify(UserId);
        let fromDataUserId = new FormData();
        fromDataUserId.append("params", UserIdString);
        let urlks = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqbm.do";
        this.$ajax.post(urlks, fromDataUserId).then(resks => {
          this.data.ks = resks.data.data[0].NAME_;
        });
      });
    },
    onConfirm(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0");
      this.data.qjrq = year + "-" + month + "-" + date;
      this.showData = false;
    },
    onConfirmStrar(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      this.data.qssj = year + "-" + month + "-" + date + hours + minutes;
      this.showDataStrar = false;
    },
    onConfirmEnd(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      this.data.zzsj = year + "-" + month + "-" + date + hours + minutes;
      this.showDataEnd = false;
      this.valuechanged();
    },
    valuechanged() {
      var beginrq = this.data.qssj;
      var endrq = this.data.zzsj;
      var date1 = new Date(beginrq);
      var date2 = new Date(endrq);
      var s1 = date1.getTime(),
        s2 = date2.getTime();
      var total = (s2 - s1) / 1000;
      var day = parseInt(total / (24 * 60 * 60)); //计算天数
      if (day < 0) {
        this.data.hjsj = "";
        this.data.xs = "";
        this.errorToast("合计时间不能为负数!");
      } else {
        this.data.hjsj = day;
      }
      var afterDay = total - day * 24 * 60 * 60; //取得算出天数后剩余的秒数
      if (afterDay < 0) {
        this.data.hjsj = "";
        this.data.xs = "";
        this.errorToast("合计时间不能为负数!");
      } else {
        this.data.xs = parseInt(afterDay / (60 * 60)); //计算小时数
      }
    },

    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    getType(str) {
      if (str == '书面提前申请') {
        return 'smqj';
      } else if (str == '口头或电话临时请假，假后补请假单') {
        return 'ktqj';
      } else if (str == '婚假') {
        return 'hj';
      } else if (str == '产假') {
        return 'cj';
      } else if (str == '护理假') {
        return 'hlj';
      } else if (str == '病假') {
        return 'bj';
      } else if (str == '工伤假') {
        return 'gsj';
      } else if (str == '丧假') {
        return 'sj';
      } else if (str == '其他') {
        return 'qt';
      }
    },
    datas() {
      let dlrName = JSON.parse(this.data.dlrName);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            xm: this.data.xm,
            ks: this.data.ks,
            qjrq: formatHMT(this.data.qjrq),
            // qjfs: this.data.qjfsName,
            xmid: this.userId,
            // jqlx:
            qssj: formatHMT(this.data.qssj),
            zzsj: formatHMT(this.data.zzsj),
            sqjqhjsj: this.data.hjsj,
            xs: this.data.xs,
            dlr: dlrName[0].id,
            dlr_name: dlrName[0].text,
            dbsx: this.data.dbsx,
            byljcs: this.data.ljcs,
            qjfs_name: this.data.qjfsName,
            qjfs: this.getType(this.data.qjfsName),
            jqlx_name: this.data.jqlxName,
            jqlx: this.getType(this.data.jqlxName)
          }
        }]
      };
      // console.log(jsonDataObj);
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.qjfsName) || this.iN(this.data.jqlxName) || this.iN(this.data.dlrName) || this.iN(this.data.qssj) || this.iN(this.data.zzsj)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      // this.$ajax.post({
      //   methods: 'post',
      //   url: url,
      //   params:params
      // })
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};